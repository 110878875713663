import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'> Brandon Lam </a>
      <ul className='permalinks'>
        <li> <a href='#'> Home </a></li>
        <li> <a href='#about'> About </a></li>
        <li> <a href='#portfolio'> Portfolio </a></li>
        <li> <a href='#experience'> Experience </a></li>
        <li> <a href='#contact'> Contact </a></li>
      </ul>

      <div className="footer__socials"> 
        <a href="https://www.linkedin.com/in/brandon-lam-034a74198/"> <BsLinkedin/> </a>
        <a href="https://github.com/Brrrandon-Lam"> <BsGithub/></a>
      </div>
    </footer>
  )
}

export default Footer